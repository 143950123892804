<template>
  <div>
    <div class="frmBlock__colorPicker">
      <button
        type="button"
        class="frmBlock__link"
        @click="
          colorpickerLayer[option.componentOption.id]
            ? hideColorPicker(option.componentOption.id, $event)
            : showColorPicker(option.componentOption.id, $event)
        "
      ></button>
      <div class="selectImage--color">
        <span
          :style="{
            'background-color': currentColor
          }"
        ></span>
      </div>
    </div>
    <div class="DAV-colorpicker">
      <ColorPicker
        :value="currentColor"
        :ref="option.componentOption.id"
        @input="updateColorPicker($event.hex8)"
      />
      <div
        class="DAV-colorpicker__preview"
        v-show="colorpickerLayer[option.componentOption.id]"
      >
        <p>이전 적용한 색상</p>
        <button
          type="button"
          class="frmBlock__colorPicker"
          @click="updateColorPicker(preColor)"
        >
          <div class="selectImage--color">
            <span
              :style="{
                'background-color': preColor
              }"
            ></span>
          </div>
        </button>
      </div>
      <button
        type="button"
        class="DAV-colorpicker--close"
        v-show="colorpickerLayer[option.componentOption.id]"
        @click="hideColorPicker(option.componentOption.id, $event)"
      >
        <span>색상 선택 접기</span>
      </button>
    </div>
  </div>
</template>

<script>
import {Chrome} from "vue-color";

export default {
  props: {
    option: {
      type: Object
    }
  },
  data() {
    return {
      colorpickerLayer: {},
      preColor: "",
      currentColor: ""
    };
  },
  computed: {
    initialValue() {
      return this.$route.path === "/option"
        ? this.option.modified.properties[0]?.initialValue ||
            this.option.componentOption.properties[0]?.initialValue
        : this.option.value.color;
    }
  },
  mounted() {
    // this.preColor = this.option.value.color;
    this.preColor = this.initialValue;
    this.currentColor = !this.initialValue ? "#000000" : this.initialValue;
    if (this.$route.path === "/option") {
      this.updateProperty(this.preColor);
    }
  },
  methods: {
    // 프로퍼티 업데이트 (파트너)
    updateProperty(value) {
      let modified = {
        id: this.option.componentOption.id,
        properties: [
          {
            id: this.option.componentOption.properties[0].id,
            initialValue: value
          }
        ]
      };
      this.$emit("updateProperty", this.option.componentOption.key, modified);
    },
    // 옵션 Update
    updateColorPicker(value) {
      this.currentColor = value;
      this.$emit(
        "updateOption",
        this.option.componentOption.key,
        this.option.componentOption.properties[0].key,
        value
      );
      if (this.$route.path === "/option") {
        this.updateProperty(value);
      }
    },
    // colorpicker show
    showColorPicker(key) {
      let el = this.$refs[key].$el;
      el.querySelector(".vc-chrome-saturation-wrap").style.display = "block";
      el.querySelector(".vc-chrome-body .vc-chrome-controls").style.display =
        "flex";
      this.$set(this.colorpickerLayer, key, true);
    },
    // colorpicker hide
    hideColorPicker(key) {
      let el = this.$refs[key].$el;
      el.querySelector(".vc-chrome-saturation-wrap").style.display = "none";
      el.querySelector(".vc-chrome-body .vc-chrome-controls").style.display =
        "none";
      this.$set(this.colorpickerLayer, key, false);
    }
  },
  components: {
    ColorPicker: Chrome
  }
};
</script>

<style lang="scss">
/* ColorPicker Custom */
.vc-chrome {
  font-family: "SpoqaHanSansNeo", "Malgun Gothic", sans-serif !important;
  width: 100% !important;
  box-shadow: none !important;
  border-radius: 8px !important;
  box-sizing: border-box !important;
  padding-top: 62px;
}
.vc-chrome-saturation-wrap {
  display: none;
  margin-top: 8px;
  padding-bottom: 45% !important;
  border-radius: 8px !important;
}
.vc-chrome-body {
  padding: 0 !important;

  .vc-chrome-controls {
    display: none;
    margin-top: 8px;
  }
  .vc-chrome-fields-wrap {
    position: absolute;
    top: -2px;
    right: 1px;
    left: 1px;
  }
}
.vc-chrome-fields {
  margin-left: 0 !important;

  .vc-chrome-field {
    padding-left: 0 !important;
  }

  &:nth-child(1) {
    /* HEX */
    margin-right: 8px !important;

    .vc-input__label {
      margin-left: 46px;
    }
    .vc-input__input {
      padding-left: 46px;
    }
  }
  &:nth-child(2) {
    /* RGBA */
    display: flex !important;

    .vc-chrome-field {
      margin-left: -1px;

      &:nth-child(1) .vc-input__input {
        border-radius: 8px 0 0 8px !important;
      }
      &:nth-child(2) .vc-input__input,
      &:nth-child(3) .vc-input__input {
        border-radius: 0 !important;
      }
      &:nth-child(4) .vc-input__input {
        border-radius: 0 8px 8px 0 !important;
      }
    }
  }
}
.vc-chrome-toggle-btn {
  display: none !important;
}
.vc-input__label {
  position: absolute;
  top: -26px;
  right: 0;
  left: 0;
  color: #606060 !important;
  font-size: 12px !important;
}
.vc-input__input {
  height: 48px !important;
  background: #ffffff !important;
  border: 1px solid #d8dae5 !important;
  box-sizing: border-box !important;
  border-radius: 8px !important;
  box-shadow: none !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}
@media screen and (max-width: 1023px) and (min-width: 600px) {
  .vc-chrome-saturation-wrap {
    padding-bottom: 35% !important;
  }
}
.DAV-colorpicker {
  position: relative;
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;

  &--close {
    display: block;
    width: 100%;
    margin-top: 16px;
    margin-bottom: -4px;
    padding-top: 12px;
    border-top: 1px solid #f1f1f1;

    span {
      padding-right: 16px;
      background-image: url($path + "bul_arr_top_gray.svg");
      background-repeat: no-repeat;
      background-position: 100% 50%;
      color: #8d8d8d;
      font-weight: 400;
      font-size: 14px;
    }
  }
  &__preview {
    margin-top: 22px;

    p {
      color: #606060;
      font-weight: 500;
      font-size: 14px;
    }
    .frmBlock__colorPicker {
      margin-top: 8px;

      .selectImage--color {
        position: relative;
        top: 0;
        left: 0 !important;
      }
    }
  }
}
.frmBlock__link {
  z-index: 1;
  position: absolute;
  top: 14px;
  left: 0;
  right: 0;
  height: 48px;
  width: 48px;
}
.frmBlock__colorPicker {
  z-index: 1;
  position: relative;
  display: flex;

  .frmBlock__textField:hover {
    border-color: #d8dae5;
    box-shadow: none;
  }
  .frmBlock__textField:focus {
    border-color: #d8dae5;
  }
}
.selectImage__icon {
  position: relative;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  margin-right: 8px;
  background-color: #f4f7ff;
  border: 1px solid #d8dae5;
  box-sizing: border-box;
  border-radius: 8px;
  text-indent: -9999px;
  background-repeat: no-repeat;
  background-position: 50% 50%;

  span {
    position: absolute;
    top: -10px;
    right: -4px;
    width: 20px;
    height: 20px;
    background-image: url($path + "ico_edit.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
  &--search {
    background-image: url($path + "ico_component_search.svg");
  }
  &--cart {
    background-image: url($path + "ico_component_cart.svg");
  }
  &--menu {
    background-image: url($path + "ico_component_menu.svg");
  }
}
.selectImage--color {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 14px;
  left: 0;
  width: 48px;
  height: 48px;
  background-color: #ffffff;
  border: 1px solid #d8dae5;
  box-sizing: border-box;
  border-radius: 8px;
  background-image: url($path + "bg_hex.svg");
  background-repeat: no-repeat;
  background-position: 50% 50%;

  span {
    width: 22px;
    height: 22px;
    border-radius: 22px;
  }
}
</style>
